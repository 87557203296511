<script setup lang="ts">
    import Dialog from 'primevue/dialog';
    import { useI18n } from 'vue-i18n';
    import FormField from '@/components/form-components/FormField.vue';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';
    import Select from 'primevue/select';
    import { LeadDto, LeadActivity } from '@containex/portal-backend-dto';
    import { nativeEnum, object, string } from 'zod';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import { leadsApi } from '@containex/portal-backend-api-client';
    import { httpClient } from '@/common/api/http-client';
    import { getLogger } from '@containex/logger';
    import { HttpStatusCode } from 'axios';
    import { hasValidationError } from '@/util/hasValidationError';
    import { isStringEmpty } from '@containex/common-utils';
    import Message from 'primevue/message';

    const props = defineProps<{
        lead: LeadDto;
    }>();

    const emits = defineEmits<{
        close: [];
        update: [LeadDto];
    }>();

    const { t } = useI18n();
    const logger = getLogger('CreateLeadDialog');
    const activities = [
        LeadActivity.OrderSales,
        LeadActivity.OrderRental,
        LeadActivity.OrderCompetitor,
        LeadActivity.OfferPriceInfo,
        LeadActivity.OfferRental,
        LeadActivity.OfferSales,
        LeadActivity.NotInteresting,
        LeadActivity.NotPossible,
    ];

    const schema = object({
        activityStatus: nativeEnum(LeadActivity),
        activityText: string().refine(validateActivityText, {
            message: t('ACCOUNT.EDIT_LEADS.ACTIVITY_TEXT_VALIDATION_MESSAGE'),
        }),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [activityStatus] = defineField('activityStatus');
    const [activityText] = defineField('activityText');

    if (props.lead != null) {
        activityStatus.value = props.lead.activity ?? undefined;
        activityText.value = props.lead.activityText ?? '';
    }

    const onSubmit = handleSubmit(async (values) => {
        try {
            const text = values.activityStatus === LeadActivity.NotInteresting ? values.activityText : undefined;
            const activity = { status: values.activityStatus, text };

            const response = await leadsApi.completeLead(httpClient, props.lead.id, {
                activity,
            });

            if (response.status === HttpStatusCode.Ok) {
                emits('update', response.data);
            } else {
                logger.error('Error while trying to complete lead', response);
            }
        } catch (error) {
            logger.error('Error while trying to complete lead', error);
        }
    });

    function translateActivity(activity: LeadActivity): string {
        return t(`ACCOUNT.LEADS.ACTIVITIES.${activity}`);
    }

    function validateActivityText(text: string): boolean {
        if (activityStatus.value === LeadActivity.NotInteresting && isStringEmpty(text)) {
            return false;
        }

        return true;
    }
</script>

<template>
    <Dialog :visible="true" modal :style="{ width: '40rem', 'max-width': '100%' }" @update:visible="emits('close')">
        <template #header>
            <div class="dialog-header-typography lead-header">{{ t('ACCOUNT.COMPLETE_LEADS.HEADER') }}</div>
        </template>
        <form class="flex-container">
            <div class="row">
                <Message severity="info" icon="pi pi-info-circle" :closable="false">{{
                    t('ACCOUNT.COMPLETE_LEADS.INFO_MESSAGE')
                }}</Message>
            </div>
            <div class="row">
                <FormField :label="t('ACCOUNT.EDIT_LEADS.ACTIVITY')">
                    <Select
                        v-model="activityStatus"
                        class="full-width"
                        data-testid="signup-form-country"
                        :options="activities"
                        :option-label="translateActivity"
                        :invalid="hasValidationError(errors.activityStatus)"
                    />
                </FormField>
            </div>
            <div v-if="activityStatus === LeadActivity.NotInteresting" class="row">
                <FormField :label="t('ACCOUNT.EDIT_LEADS.ACTIVITY_TEXT')" :error-message="errors.activityText">
                    <Textarea
                        v-model="activityText"
                        rows="6"
                        class="full-width internal-notes-textarea"
                        :invalid="hasValidationError(errors.activityText)"
                    />
                </FormField>
            </div>
        </form>
        <div class="lead-footer">
            <Button type="button" outlined :label="t('COMMON.CANCEL')" @click="emits('close')" />
            <Button type="submit" :label="t('ACCOUNT.COMPLETE_LEADS.COMPLETE')" @click="onSubmit" />
        </div>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .lead-footer {
        display: flex;
        gap: 1ch;
        padding-bottom: main.$spacing-5;
        justify-content: flex-end;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .flex-container {
        display: flex;
        flex-direction: column;
        padding-bottom: main.$spacing-6;
    }

    .row {
        display: flex;
        flex-direction: column;
        column-gap: main.$spacing-5;
    }
</style>
